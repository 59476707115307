// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/rustemginiyatullin/my/src/loyaltypro/landing/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/Users/rustemginiyatullin/my/src/loyaltypro/landing/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/rustemginiyatullin/my/src/loyaltypro/landing/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("/Users/rustemginiyatullin/my/src/loyaltypro/landing/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("/Users/rustemginiyatullin/my/src/loyaltypro/landing/src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-prices-js": () => import("/Users/rustemginiyatullin/my/src/loyaltypro/landing/src/pages/prices.js" /* webpackChunkName: "component---src-pages-prices-js" */),
  "component---src-pages-signup-js": () => import("/Users/rustemginiyatullin/my/src/loyaltypro/landing/src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-solutions-js": () => import("/Users/rustemginiyatullin/my/src/loyaltypro/landing/src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */)
}

exports.data = () => import("/Users/rustemginiyatullin/my/src/loyaltypro/landing/.cache/data.json")

