module.exports = [{
      plugin: require('/Users/rustemginiyatullin/my/src/loyaltypro/landing/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/rustemginiyatullin/my/src/loyaltypro/landing/node_modules/gatsby-plugin-yandex-metrika/gatsby-browser'),
      options: {"plugins":[],"trackingId":"52246198","webvisor":true,"trackHash":true},
    },{
      plugin: require('/Users/rustemginiyatullin/my/src/loyaltypro/landing/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
